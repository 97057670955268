var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"api-notify-handler-module-view"},[_c('page-title',{attrs:{"tags":_vm.getTagsByFieldKey('integrations'),"title":_vm.$t(_vm.model.model.titleKey)},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),(!_vm.getTagsByFieldKey('integrations') && _vm.hiddenToken)?_c('save-module-button',{on:{"save-module":_vm.saveModule}}):_vm._e()]},proxy:true}])}),(_vm.getTagsByFieldKey('integrations'))?_c('not-available-options-overlay'):[_c('a-button',{attrs:{"block":"","loading":_vm.generateTokenLoading,"type":"primary"},on:{"click":_vm.generateToken}},[_vm._v(" "+_vm._s(_vm.hiddenToken ? _vm.$t('external_integrations_generate_token_again') : _vm.$t('external_integrations_generate_token'))+" ")]),_c('config-field',{staticClass:"my-2",attrs:{"title-visible":false,"mini-help-message":_vm.$t('external_integrations_generate_token_mini_help_message')}}),(_vm.hiddenToken)?_c('a-card',[_c('config-field',{attrs:{"inline":"","title":_vm.$t('external_integrations_token')}},[_c('code',[_vm._v(" "+_vm._s(_vm.hiddenToken)+" ")])])],1):_vm._e(),(_vm.hiddenToken && _vm.$store.getters.isChatSet && _vm.$store.state.chatState.chat.version >= 6000)?_c('a-card',{staticClass:"api-integration mt-5"},[_c('text-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.model.model.params,
            'key': 'api_notify_url',
            'validation': 'required|url',
            'hasAnchor': true,
          }
        }}}),_vm._l((Object.keys(_vm.types)),function(typeKey,index){return _c('checkbox-input',{key:index,staticClass:"last:mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.types,
            'key': typeKey,
            'hasAnchor': true,
          }
        }},on:{"change":_vm.onChange}})})],2):_vm._e()],_c('a-modal',{attrs:{"body-style":{
      paddingTop: '48px'
    },"footer":null,"after-close":_vm.afterModalClose},model:{value:(_vm.isShowTokenPopupOpen),callback:function ($$v) {_vm.isShowTokenPopupOpen=$$v},expression:"isShowTokenPopupOpen"}},[_c('a-alert',{attrs:{"show-icon":""}},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('external_integrations_attention_message'))},slot:"message"})]),_c('config-field',{staticClass:"mt-2",attrs:{"title":_vm.$t('external_integrations_token_title')}},[(_vm.token)?_c('code',{staticClass:"block"},[_vm._v(" "+_vm._s(_vm.token)+" ")]):_vm._e(),_c('a-button',{staticClass:"mt-2",attrs:{"block":"","type":"primary","icon":"copy"},on:{"click":_vm.copyToken}},[_vm._v(" "+_vm._s(_vm.$t('external_integrations_copy_token'))+" ")]),(_vm.isTokenCopied)?_c('a-alert',{staticClass:"mt-2",attrs:{"show-icon":"","type":"success","message":_vm.$t('external_integrations_token_was_copied')}}):_vm._e()],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }