
































































































































import { InputSetups } from '@/mixins/input-setups'
import ChatApi from '@/includes/Api/Chat.api'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import { errorNotification } from '@/includes/NotificationService'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import ApiNotifyHandlerModuleBuilder
  from '@/includes/logic/Modules/models/modules/ApiNotifyHandlerModule/ApiNotifyHandlerModuleBuilder'
import { ApiNotifyTypes } from '@/includes/logic/Modules/models/modules/ApiNotifyHandlerModule/ApiNotifyHandlerModule'

import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import ClipboardCopyMixin from 'piramis-base-components/src/logic/helpers/ClipboardCopyMixin'
import TextInput from 'piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue'
import CheckboxInput from 'piramis-base-components/src/components/Pi/fields/CheckboxInput/CheckboxInput.vue'
import CenteredColumnLayout from 'piramis-base-components/src/components/CenteredColumnLayout.vue'

import { Component, Mixins, VModel, Watch } from 'vue-property-decorator'

@Component({
  components: {
    ConfigField,
    CheckboxInput,
    SwitchInput,
    TextInput,
    NotAvailableOptionsOverlay,
    CenteredColumnLayout
  },
})
export default class ApiNotifyHandlerModuleView extends Mixins(ModuleBindings, TariffsTagsHelper, InputSetups, ClipboardCopyMixin) {
  @VModel() model!: ApiNotifyHandlerModuleBuilder

  get chat() {
    return this.$store.state.chatState.chat
  }

  types: Record<ApiNotifyTypes, boolean> = {
    EnterNewMember: false,
    ReturnNewMember: false,
    CaptchaNotVerifiedKick: false,
    UserReEnterBan: false,
    KickSecondInactive: false,
    KickInactive: false,
    MemberLeave: false,
    MemberKicked: false,
  }

  hiddenToken: string | null = this.chat && !!this.chat!.access_token ? this.chat!.access_token : null

  @Watch('hiddenToken', { immediate: true })
  onTokenChange() {
    if (this.hiddenToken) {
      this.$baseTemplate.saveButton.show(this.saveModule)
    } else {
      this.$baseTemplate.saveButton.hide()
    }
  }

  generateTokenLoading = false

  isShowTokenPopupOpen = false

  token: string | null = null

  isTokenCopied = false

  onChange() {
    this.model.model.params.api_notify_types = (Object.keys(this.types) as Array<ApiNotifyTypes>).filter(k => this.types[k])
  }

  copyToken() {
    if (this.token) {
      this.copy(this.token)

      this.isTokenCopied = true
    }
  }

  afterModalClose() {
    this.token = null
    this.isTokenCopied = false
  }

  generateToken() {
    ChatApi.generateAccessToken(this.chat!.chat_id)
      .then(({ data }) => {
        this.token = data.access_token

        this.hiddenToken = `${ this.token!.substring(0, 5) }**********${ this.token!.substring(this.token!.length - 5) }`

        this.isShowTokenPopupOpen = true
      })
      .catch(errorNotification)
      .finally(() => {
        this.generateTokenLoading = false
      })
  }

  created() {
    this.model.model.params.api_notify_types.forEach(type => this.types[type] = true)
  }
}
